header {
   transition: all 300ms;
   height: 96px;
   align-items: center;
}

header .container, header .logo, header .start-trigger {
    transition: all 300ms;
}

header .logo {
    @apply text-secondary;
}

.nav-logo-hide header {
    visibility: hidden;
}

.nav-minimize header {
    position: fixed;
    height: 60px;
    @apply bg-primary;
    @apply shadow-lg;
}

.nav-minimize .logo {
    mix-blend-mode: normal;
    visibility: visible;
    @apply text-white;
}

.nav-minimize .start-trigger {
    @apply py-2;
    @apply px-4;
    @apply text-base;
}

@media screen and (max-width: 640px) {
    .start-trigger {
        @apply py-2;
        @apply px-4;
        @apply text-base;
    }
}

.nav-hide header {
    height: 96px;
    pointer-events: none;
    touch-action: none;
    background-color: transparent;
    box-shadow: none !important;
}

.nav-hide .logo, .nav-hide .start-trigger {
    visibility: hidden;
}

#menu-toggle {
    pointer-events: all !important;
    touch-action: auto !important;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
}

#menu-toggle .background {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 100%;
    width: 70px;
    height: 70px;
    transform: scale(0);
    isolation: isolate;
}

#menu-toggle .inner {
    position: relative;
    z-index: 5;
    width: 30px;
    height: 30px;   
}

#menu-toggle hr {
    position: absolute;
    border: none;
    height: 5px;
    width: 30px;
    @apply bg-secondary;
}

#menu-toggle hr:nth-child(1) {
    top: 0px;
}

#menu-toggle hr:nth-child(2) {
    top: 10px;
}

#menu-toggle hr:nth-child(3) {
    top: 20px;
}

#menu .text-title {
    font-size: 10vw;
    font-size: max(108px, 10vw);
}