.scrolling-background .scrolling-text {
    position: relative;
    z-index: 5;
    opacity: .6;
}

.scrolling-background .scrolling-text .scrolling-text-inner span {
    color: #EDF500;
    line-height: .8;
}

#menu nav.main-navigation {
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    position: relative;
}

#menu nav.main-navigation ol {
    height: 100vh;
    overflow: hidden;
}

#menu nav.main-navigation .fader {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 20rem;
    width: 100%;
    background: linear-gradient(0deg, #F7FF07, transparent);
    pointer-events: none;
    touch-action: none;
    z-index: 1;
}

#menu nav.main-navigation ol li a .label {
    @apply text-secondary;
    transition: all 300ms;
}

#menu nav.main-navigation ol li a:hover .label {
    @apply text-primary;
}

#menu nav.social-navigation {
    z-index: 10;;
}

#menu nav.social-navigation ol li a {
    display: inline-block;
    transition: all 300ms;
}

#menu nav.social-navigation ol li a:hover {
    transform: scale(1.2,1.2);
    @apply text-primary;
}